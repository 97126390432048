import { useState } from "react"
import { motion } from "framer-motion"
import { ArrowRight, Bot, Brain, Rocket, Cpu, Zap, MessageSquare, Workflow } from "lucide-react"

const TabCategory = ({ text, isSelected, onClick }) => (
  <button
    onClick={onClick}
    className={`px-6 py-2 text-base md:text-lg transition-all duration-300 border-b-2 ${
      isSelected ? "text-white border-purple-500 font-semibold" : "text-gray-400 border-transparent hover:text-gray-300"
    }`}
  >
    {text}
  </button>
)

const ServiceCard = ({ icon: Icon, text }) => (
  <motion.div
    initial={{ opacity: 0, y: 20 }}
    animate={{ opacity: 1, y: 0 }}
    whileHover={{ scale: 1.05 }}
    className="flex items-center gap-3 px-6 py-3 bg-gradient-to-br from-purple-900/50 to-indigo-900/50 
               rounded-full backdrop-blur-sm border border-purple-500/20 cursor-pointer 
               hover:border-purple-500/40 hover:shadow-lg hover:shadow-purple-500/10 transition-all group"
  >
    <Icon className="w-5 h-5 text-purple-400 group-hover:text-purple-300" />
    <span className="text-white text-sm md:text-base font-medium whitespace-nowrap">{text}</span>
  </motion.div>
)

const Home = () => {
  const [selectedTab, setSelectedTab] = useState("AI Solutions")
  const [isMenuActive, setIsMenuActive] = useState(false)
  const [message, setMessage] = useState("")
  const [showMessage, setShowMessage] = useState(false)

  const showNotification = (text) => {
    setMessage(text)
    setShowMessage(true)
    setTimeout(() => setShowMessage(false), 3000)
  }

  const categories = {
    "AI Solutions": [
      { icon: Brain, text: "Generative AI Integration" },
      { icon: Bot, text: "Chatbot Development" },
      { icon: MessageSquare, text: "AI Voice Assistants" },
      { icon: Workflow, text: "AI Workflow Automation" },
      { icon: Rocket, text: "Machine Learning Models" },
    ],
    Enterprise: [
      { icon: Cpu, text: "Enterprise AI Systems" },
      { icon: Zap, text: "Process Automation" },
      { icon: Brain, text: "Custom AI Models" },
      { icon: Bot, text: "Enterprise Chatbots" },
      { icon: Workflow, text: "Workflow Integration" },
    ],
    Solutions: [
      { icon: MessageSquare, text: "AI Consulting" },
      { icon: Rocket, text: "Strategic Planning" },
      { icon: Workflow, text: "Implementation" },
      { icon: Brain, text: "AI Training" },
      { icon: Bot, text: "Support Services" },
    ],
  }

  return (
    <div className="relative overflow-hidden min-h-screen">
      {/* Background Elements */}
      <div
        className="fixed inset-0 bg-cover bg-center z-0"
        style={{
          backgroundImage: "url('/worldbackground.png')",
          backgroundAttachment: "fixed",
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      />
      <div className="fixed inset-0 bg-gradient-to-br from-purple-900/70 to-indigo-900/70 z-0" />

      <div className="relative z-1 container mx-auto px-4">
        <div className="min-h-screen flex flex-col justify-center pb-24">
          {/* Hero Section */}
          <section className="text-center max-w-5xl mx-auto">
            {/* Hero Section */}
            <div className="relative flex flex-col items-center">
              <motion.h1
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5 }}
                className="text-3xl sm:text-4xl md:text-5xl lg:text-6xl font-bold mb-6 text-white leading-tight"
              >
                Transform Your Business with AI Solutions
              </motion.h1>
              <motion.p
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5, delay: 0.2 }}
                className="text-lg md:text-xl text-gray-300 mb-12"
              >
                Share your requirements & we'll create the perfect AI solution for your project
              </motion.p>

              {/* Original Get Started Button */}
              <motion.div
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5, delay: 0.3 }}
                className="mb-12" // Changed from "mb-24" to "mb-12"
              >
                <button
                  onClick={() => window.open("https://cal.com/tugadot/quickconnect", "_blank")}
                  className="group relative inline-flex items-center gap-3 px-8 py-4 
                               border-2 border-purple-500/50 hover:border-purple-400
                               rounded-full text-white font-semibold text-lg 
                               transition-all duration-300 
                               hover:scale-105
                               hover:shadow-lg hover:shadow-purple-500/20"
                >
                  <span className="relative z-10 flex items-center gap-2">
                    Get Started
                    <ArrowRight className="w-5 h-5 group-hover:translate-x-1 transition-transform" />
                  </span>
                  <div className="absolute -top-3 -right-3">
                    <div
                      className="px-3 py-1 bg-gradient-to-r from-purple-500 to-purple-600 
                                text-xs font-bold rounded-full shadow-lg 
                                border border-purple-400/30 text-white/90
                                backdrop-blur-sm"
                    >
                      FREE
                    </div>
                  </div>
                </button>
              </motion.div>

              {/* Message Popup */}
              {showMessage && (
                <motion.div
                  initial={{ opacity: 0, y: 10 }}
                  animate={{ opacity: 1, y: 0 }}
                  exit={{ opacity: 0 }}
                  className="absolute top-[-60px] left-1/2 transform -translate-x-1/2 
                           bg-purple-900/40 backdrop-blur-md rounded-full px-6 py-3
                           border border-purple-500/20 shadow-lg"
                >
                  <p className="text-white text-sm">{message}</p>
                </motion.div>
              )}
            </div>
          </section>

          {/* Categories Section */}
          <section className="mt-8 -mb-24">
            {" "}
            {/* Changed from "mt-24" and added negative bottom margin */}
            <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ duration: 0.5, delay: 0.5 }}>
              <h2 className="text-xl md:text-2xl font-semibold text-white mb-8 text-center">Explore Our Expertise</h2>

              {/* Tabs */}
              <div className="flex justify-center gap-8 mb-12 border-b border-gray-700/50">
                {Object.keys(categories).map((tab) => (
                  <TabCategory
                    key={tab}
                    text={tab}
                    isSelected={selectedTab === tab}
                    onClick={() => setSelectedTab(tab)}
                  />
                ))}
              </div>

              {/* Service Cards */}
              <motion.div
                key={selectedTab}
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.3 }}
                className="flex flex-wrap justify-center gap-4"
              >
                {categories[selectedTab].map((service) => (
                  <ServiceCard key={service.text} icon={service.icon} text={service.text} />
                ))}
              </motion.div>
            </motion.div>
          </section>
        </div>
      </div>
    </div>
  )
}

export default Home

